import React, { useEffect, useCallback } from "react";
import {
  Link,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { Container, Card, Row, Col, Badge, Button } from "react-bootstrap";

import { signInWithGoogle, usePostSigninWithGoogleResult } from '../../lib/firebase';
import { gql, useGraphMutation } from '../../hooks/graphql-hook';

const LOGIN_MUTATION = gql`mutation($t:String!) {
    authFirebase(fbToken:$t) {
        id
        jwt
    }
}`;


export default function LoginPage() {
    /* We have some fancy react hooks to:
     *  1. get a firebase token (on page reload)
     *  2. trigger a mutation to Crosseye to get our auth token
     *  3. save auth token
     *  4. navigate to the portal
     * Note: the code kinda reads in reverse.
     */
    const navigate = useNavigate();
    const loginMutation = useGraphMutation(LOGIN_MUTATION);
    // const loginMutationData = loginMutation.data;
    // useEffect(()=>{
    //     const jwt = loginMutationData?.jwt;
    //     if (jwt != null && jwt != "") {
    //         localStorage.setItem('auth:jwt', jwt);
    //         navigate(`/dashboard/`);
    //     }
    // }, [loginMutationData, navigate]);
    const loginMutationMutate = loginMutation.mutate;
    const setFirebaseToken = useCallback((token) => {
        loginMutationMutate({
            variables: {t:token},
            update: (cache, { data: { authFirebase } }) => {
                console.log(`Login verification from our server gave us`, authFirebase);
                const jwt = authFirebase?.jwt;
                if (jwt != null && jwt != "") {
                    localStorage.setItem('auth:jwt', jwt);
                    navigate(`/dashboard/`);
                }
            },
        });
    }, [loginMutationMutate, navigate]);
    // usePostSigninWithGoogleResult(setFirebaseToken);  // Ensure we capture the redirected login token

    return (<>
      <div className="bg-primary py-5">

        {/* <Container>
          <Card>
            <Card.Body>
              <h3 className="text-center">Login</h3>
            </Card.Body>
          </Card>
        </Container> */}


  <Card className="ms-auto me-auto w-50" role="document">
    <Card.Body className="rounded-4 shadow">
      <div className="modal-header p-5 pb-4 border-bottom-0">
        <h1 className="fw-bold mb-0 fs-2">CrosseyeVR - Sign up or login</h1>
        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>

      <div className="modal-body p-5 pt-0">
          <Button className="w-100 py-2 mb-2 btn-white rounded-3" onClick={()=>signInWithGoogle(setFirebaseToken)}>
            Google Account
          </Button>
        {/* <form className="">
          <div className="form-floating mb-3">
            <input type="email" className="form-control rounded-3" id="floatingInput" placeholder="name@example.com"/>
            <label >Email address</label>
          </div>
          <div className="form-floating mb-3">
            <input type="password" className="form-control rounded-3" id="floatingPassword" placeholder="Password"/>
            <label >Password</label>
          </div>
          <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Sign up</button>
          <small className="text-muted">By clicking Sign up, you agree to the terms of use.</small>
          <hr className="my-4" />
          <h2 className="fs-5 fw-bold mb-3">Or use a third-party</h2>
        </form> */}
      </div>
    </Card.Body>
  </Card>

        
      </div>

        </>
      );    
}